import { graphql, useStaticQuery } from 'gatsby'
import React from 'react';



const Contact = () => {
    const contactDataMerci = useStaticQuery(graphql`
        query contactDataMerciQuery {
            contactJson {
                subtitle
            }
        }
    `);
    const Description = contactDataMerci.contactJson.subtitle;
    return (
        <div className="rn-contact-area rn-section-gap bg-color-white" id="contact"> 
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="section-title mb--30">
                            <span className="subtitle">{Description}</span>
                            <h2 className="title">Votre requête a bien été envoyée. </h2>
                            <p>Merci beaucoup pour votre intérêt. Vous devriez recevoir une réponse d'ici quelques jours.</p>
                      
                        </div>
                    </div>
                </div>

              
            </div>
        </div>
    )
}

export default Contact
