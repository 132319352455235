import React from "react";
import Layout from "../components/layout";
import Contactmerci from "../elements/contact/contactmerci";
import Banner from "../components/banner";
import Seo from "../components/seo";

const ContactPage = () => (
    <Layout>
        <Seo 
        title="Marie-Luce Beraudo, spécialiste SEO basée à Crozet, pays de Gex" 
        description="Vous avez besoin d'un devis pour un audit SEO ? N'hésitez pas à me contacter. Je suis basée dans le pays de Gex près de Genève. Je propose mes services de référencement et développement Web en France et en Suisse."
        />

        <Banner title="Me contacter"></Banner>
        <Contactmerci></Contactmerci>
    </Layout>

)
export default ContactPage;